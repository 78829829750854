<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row justify="end">
      <v-col>

      </v-col>
      <v-col style="text-align: right">
        <v-btn
          color="secondary_dark"
          dark
          depressed
          tile
          :to="'/baza-wiedzy/edytuj/'+id"
        >
          Edytuj
        </v-btn>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col>
        <v-card
          class="classic_card"
        >
          <v-card-title>{{data.title}}</v-card-title>
          <v-card-text><p v-html="data.description"></p></v-card-text>
        </v-card>

        <v-card 
          class="card_section" 
          v-if="data.files.length"
        >
          <v-card-title>Załączniki</v-card-title>
          <v-card-text>
            <div
              v-for="file in data.files" :key="file.id"
              style="margin-bottom: 8px;"
            >
              <v-btn
                @click="open_file(file.id,file.name)"
                color="grey"
                class="white--text"
              >
                {{file.name}}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Baza wiedzy',
        disabled: false,
        to: '/baza-wiedzy-lista',
      },
      {
        text: 'Kategoria',
        disabled: false,
        to: '/baza-wiedzy/kategoria/',
      },
      {
        text: 'Szczegóły',
        disabled: true,
        to: '/baza-wiedzys/zobacz',
      },
    ],

    id: 0,
    category: 0,

    data: {
      title: '',
      description: '',
      files: []
    },
  }),
  methods: {
    open_file(id,file_name){
      this.$store.dispatch('download_file', { id: id,name: file_name });
    }
  },
  mounted(){
    this.id = this.$route.params.id;

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/faq/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        this.breadcrumb[2].to = '/baza-wiedzy/kategoria/'+resp.data.categories_id;
        this.data = resp.data;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.classic_table {
  margin-top: 25px;
}
.card_section {
  margin-top: 25px;
}
</style>